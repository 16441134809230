<template>
  <div>
    <div class="table-container is-centered" :style="{ height: computedHeight }">
      <div class="table-container__table-general" ref="tableContainer">
        <div class="table-container__table-general__header-container" :style="headerStyles">
          <img :src="headerIcon" width="28" alt="Logo" />
          <span :style="headerStyles">Tabla General</span>
        </div>
        <div class="table-container__table-general__header-container-ins">
          Haz click en cualquier equipo
        </div>
        <div class="table-container__table-general__head">
          <div
            class="table-container__table-general__head__div-table-col-head position"
            :style="headerTable"
            align="center"
          >
            POS
          </div>
          <div class="table-container__table-general__head__div-table-col-head teamtable" :style="headerTable">
            EQUIPO
          </div>
          <div class="table-container__table-general__head__div-table-col-head jj" :style="headerTable">JJ</div>
          <div class="table-container__table-general__head__div-table-col-head jg" :style="headerTable">JG</div>
          <div class="table-container__table-general__head__div-table-col-head je" :style="headerTable">JE</div>
          <div class="table-container__table-general__head__div-table-col-head jp" :style="headerTable">JP</div>
          <div
            class="table-container__table-general__head__div-table-col-head gf movil-game-flag-g"
            :style="headerTable"
          >
            GF
          </div>
          <div
            class="table-container__table-general__head__div-table-col-head gc movil-game-flag-g"
            :style="headerTable"
          >
            GC
          </div>
          <div
            class="table-container__table-general__head__div-table-col-head dg movil-game-flag-g"
            :style="headerTable"
          >
            DG
          </div>
          <div class="table-container__table-general__head__div-table-col-head pts" :style="headerTable">PTS</div>
          <div
            class="table-container__table-general__head__div-table-col-head lastgames movil-game-flag-lastgame"
            :style="headerTable"
          >
            ÚLTIMOS JUEGOS
          </div>
          <div
            class="table-container__table-general__head__div-table-col-head lastgames movil-game-flag-lastgame-movil"
            :style="headerTable"
          >
            U. JUEGOS
          </div>
          <div class="table-container__table-general__head__div-table-col-head prox" :style="headerTable">PROX</div>
        </div>
        <div :class="{ 'container-max-table': !isSingleComponent && fullTableShowHidden }">
          <div class="table-container__table-general__div-table">
            <template v-if="tableData">
              <template v-for="(data, index) in tableData">
                <div
                  class="table-container__table-general__div-table-row"
                  :key="index"
                  :class="{ 'movil-hidden-table-row': data.table_position > 9 && !fullTableShowHidden && flaghideShow }"
                >
                  <div class="table-container__table-general__div-table-row__div-table-col position">
                    {{ data.table_position }}.
                  </div>
                  <div class="table-container__table-general__div-table-row__div-table-col logo">
                    <img
                      :src="'https://az755631.vo.msecnd.net/teams-80/' + data.team_id + '.png'"
                      width="22"
                      :alt="data.team_name"
                    />
                  </div>
                  <div
                    class="table-container__table-general__div-table-row__div-table-col teamtable"
                    @click="goToTeamProfile(data.team_id)"
                  >
                    {{ data.team_short_name }}
                  </div>
                  <div class="table-container__table-general__div-table-row__div-table-col jj">
                    {{ data.games_played }}
                  </div>
                  <div class="table-container__table-general__div-table-row__div-table-col jg">
                    {{ data.games_won }}
                  </div>
                  <div class="table-container__table-general__div-table-row__div-table-col je">
                    {{ data.games_tied }}
                  </div>
                  <div class="table-container__table-general__div-table-row__div-table-col jp">
                    {{ data.games_lost }}
                  </div>
                  <div class="table-container__table-general__div-table-row__div-table-col gf movil-game-flag-g">
                    {{ data.goals_scored }}
                  </div>
                  <div class="table-container__table-general__div-table-row__div-table-col gc movil-game-flag-g">
                    {{ data.goals_received }}
                  </div>
                  <div class="table-container__table-general__div-table-row__div-table-col dg movil-game-flag-g">
                    {{ data.goals_difference }}
                  </div>
                  <div class="table-container__table-general__div-table-row__div-table-col pts">{{ data.points }}</div>
                  <div class="table-container__table-general__div-table-row__div-table-col lastgames">
                    <template v-if="data.last_five_games.length > 0">
                      <template v-for="(game, index) in data.last_five_games">
                        <div
                          :key="index"
                          class="table-container__table-general__div-table-row__div-table-col__game red"
                          :class="[
                            game,
                            data.last_five_games.length == 5
                              ? 'movil-game-flag-' + index
                              : data.last_five_games.length > 3
                              ? 'movil-game-flag-' + index + 1
                              : '',
                          ]"
                        >
                          {{ game }}
                        </div>
                      </template>
                    </template>
                    <div v-else>
                      -
                    </div>
                  </div>
                  <div class="table-container__table-general__div-table-row__div-table-col prox">
                    <template v-if="data.next_rival_logo != '-'">
                      <img
                        :src="'https://az755631.vo.msecnd.net/teams-80/' + data.next_rival + '.png'"
                        width="22"
                        :alt="data.next_rival_name"
                      />
                    </template>
                    <template v-else><span>-</span></template>
                  </div>
                </div>
              </template>
            </template>
            <template v-else>
              <template v-if="fullTableShowHidden">
                <template v-for="index in 18">
                  <div :key="index" class="placeholder-item"></div>
                </template>
              </template>
              <template v-else>
                <template v-for="index in 9">
                  <div :key="index" class="placeholder-item"></div>
                </template>
                <div class="table-container__table-general__button-container">
                  <div class="button-more button-movil-show" style="visibility: hidden;">Ver completa</div>
                </div>
              </template>
            </template>
          </div>
        </div>
        <div
          v-if="tableData"
          class="table-container__table-general__button-container"
          :class="{ 'show-short-version': $route.name !== 'table' }"
        >
          <div class="button-more button-movil-show" v-if="flaghideShow" @click="buttonMore">
            Ver completa
          </div>
          <div class="button-more button-movil-show" v-if="!flaghideShow" @click="buttonLess">
            Ver menos
          </div>
        </div>
        <div class="table-container__table-general__footer-references">
          <div><span class="abbr-references">JJ</span> - Juegos jugados</div>
          <div><span class="abbr-references">JG</span> - Juegos ganados</div>
          <div><span class="abbr-references">JE</span> - Juegos empatados</div>
          <div><span class="abbr-references">JP</span> - Juegos perdidos</div>
          <div><span class="abbr-references">GF</span> - Goles a favor</div>
          <div><span class="abbr-references">GC</span> - Goles en contra</div>
          <div><span class="abbr-references">DG</span> - Diferencia de goles</div>
          <div><span class="abbr-references">PTS</span> - Puntos</div>
        </div>
      </div>
    </div>

    <div
      :class="{ 'min-div-footer-inner': !fullTableShowHidden, 'min-div-footer': fullTableShowHidden }"
      :style="widthFooter"
    >
      <div class="table-container__table-general__footer">
        <div>
          <img class="img-table-footer" src="/images/logo_golstats_horizontal_black.svg" width="87" alt="GolStats" />
        </div>
        <template v-if="colorsConfig.show_logo && showLogos">
          <div class="spacer" />
          <BetLogo
            height="28px"
            width="auto"
            :url-logo="colorsConfig.secondary_logo"
            :url-link="colorsConfig.url_base"
          />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { clickTopsAnalisisDeEquipo } from '@/utils/analytics';
import axios from 'axios';
import { mapMutations, mapGetters, mapState } from 'vuex';
export default {
  name: 'TableComponent',
  components: {
    BetLogo: () => import('@/components/Elements/BetLogo'),
  },
  props: {
    season: {
      type: Number,
    },
    custom: {
      type: Object,
      default: null,
    },
    /**
     * Indicates if must await for the season prop, or not to get table list,
     * if not, tournaments will be fetched to get a default season
     * {boolean} awaitForSeasons
     */
    awaitForSeason: {
      type: Boolean,
      default: true,
    },
    showLogos: {
      type: Boolean,
      default: true,
    },
    isSingleComponent: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    season: {
      immediate: true,
      handler: function(newValue) {
        if (newValue != null) {
          this.tableData = null;
          this.getTableList(newValue);
        }
      },
    },
  },
  data() {
    return {
      url: 'https://jfxv8fqkn3.execute-api.us-west-2.amazonaws.com/prod/generalTable/',
      tournamentsUrl: 'https://17zyhpyc7c.execute-api.us-west-2.amazonaws.com/prod/tournaments',
      retries: 3,
      tableData: null,
      footer: 548,
      tableFull: true,
      flaghideShow: true,
      heightTable: '699px',
    };
  },
  async created() {
    try {
      window.addEventListener('resize', this.myEventHandler);
      if (!this.awaitForSeason) {
        const tournaments = await this.fetchTournaments(3);
        const mxLeague = tournaments.find(tournament => tournament._id === 1);
        const regularSeason = mxLeague.seasons[0];
        await this.getTableList(regularSeason.id);
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  mounted() {
    this.footer = this.$refs.tableContainer.clientWidth;
    if (this.$route.name === 'viewAll' && this.$refs.tableContainer.clientWidth < 680) {
      this.tableFull = false;
      this.heightTable = 'auto';
    } else {
      this.tableFull = true;
      this.heightTable = '699px';
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.myEventHandler);
  },
  computed: {
    ...mapState('profileGeneral', ['colorsConfig']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    computedHeight() {
      return this.isSingleComponent ? '100%' : this.heightTable;
    },
    headerIcon() {
      if (this.$route.query.logo) {
        return this.$route.query.logo;
      } else {
        if (this.custom != null) {
          if (Object.keys(this.custom).length) {
            return this.custom.logo;
          } else {
            return 'https://az755631.vo.msecnd.net/content/shieldLogoPc.png';
          }
        } else {
          return 'https://az755631.vo.msecnd.net/content/shieldLogoPc.png';
        }
      }
    },
    headerStyles() {
      if (this.custom != null) {
        if (Object.keys(this.custom).length) {
          return {
            color: this.custom.headerColor,
            background: this.custom.headerBackground,
            borderColor: this.custom.headerBorderColor,
          };
        } else {
          return {
            color: this.$route.query.headerColor || '#fff',
            background: this.$route.query.headerBackground || '#242424',
            borderColor: this.$route.query.headerBorderColor || '#88da69',
          };
        }
      } else {
        return {
          color: this.$route.query.headerColor || '#fff',
          background: this.$route.query.headerBackground || '#242424',
          borderColor: this.$route.query.headerBorderColor || '#88da69',
        };
      }
    },
    fullTableShowHidden() {
      return this.tableFull;
    },
    widthFooter() {
      return {
        width: this.footer + 2 + 'px',
      };
    },
    headerTable() {
      if (this.custom != null) {
        if (Object.keys(this.custom).length) {
          return {
            color: '#132839',
            background: '#f7faf9',
          };
        } else {
          return {
            color: this.$route.query.subHeaderColor || '#000',
            background: this.$route.query.subHeaderBackground || '#cce8b5',
          };
        }
      } else {
        return {
          color: this.$route.query.subHeaderColor || '#000',
          background: this.$route.query.subHeaderBackground || '#cce8b5',
        };
      }
    },
  },
  methods: {
    ...mapMutations(['setIsSectionLoading']),
    ...mapMutations(['SET_PREMIUM_MESSAGE']),
    myEventHandler() {
      this.footer = this.$refs.tableContainer.clientWidth;
      if (this.$route.name === 'viewAll' && this.$refs.tableContainer.clientWidth < 680) {
        this.tableFull = false;
        this.heightTable = 'auto';
      } else {
        this.tableFull = true;
        this.heightTable = '699px';
      }
    },
    buttonMore() {
      if ('viewAll' === this.$route.name) {
        this.$router.push({ name: 'table' });
      } else {
        this.flaghideShow = false;
      }
    },
    buttonLess() {
      this.flaghideShow = true;
    },
    async getTableList(season) {
      try {
        const seasonApi = season == null ? 1401 : season;
        const data = await axios
          .get(this.url + seasonApi)
          .then(response => response)
          .catch(e => {
            if (this.retries > 0) {
              this.retries = this.retries - 1;
              return this.getTableList(seasonApi);
            }
            return e;
          });
        this.tableData = data.data.data;
        for (let index = 0; index < this.tableData.length; index++) {
          this.tableData[index].last_five_games = this.tableData[index].last_five_games.reverse();
        }
        this.$emit('data-fetched');
      } catch (e) {
        return Promise.reject(e);
      }
    },
    goToTeamProfile(team) {
      const seasonApi = this.season == null ? 1401 : this.season;
      clickTopsAnalisisDeEquipo({
        team_id: team,
        season_id: seasonApi,
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
      });
      if (['viewAll', 'table'].includes(this.$route.name)) {
        this.$router.push({
          name: 'teamProfile',
          query: { team: team, season: seasonApi, isLanding: true },
        });
      } else {
        const routeData = this.$router.resolve({ name: 'teamprofile', query: { team: team, season: seasonApi } });
        window.open(routeData.href, '_blank');
      }
    },
    /**
     * @author Alejandro Martinez
     * @param {number} retries
     * @return {Promise<any|undefined>}
     */
    async fetchTournaments(retries) {
      try {
        const { data } = await axios.get(this.tournamentsUrl);
        return data;
      } catch (e) {
        if (retries > 0) {
          return this.fetchTournaments(retries - 1);
        }
        throw new Error(e);
      }
    },
  },
};
</script>
<style>
html > body {
  padding-top: 0 !important;
}
.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: calc(100% - 1px);
}
</style>
<style lang="scss" scoped>
.container-max-table {
  width: 100%;
  height: 577px;
  max-height: 577px;
  overflow: auto;
  width: 100%;
}
.button-more {
  width: 280px;
  height: 50px;
  display: none;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 10px;
  font-family: 'Circular-Std';
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 40px;
  letter-spacing: -0.15px;
  text-align: center;
  color: #4f5151;
  border-radius: 14.3px;
  border: solid 5.5px #e6ebee;
}
.movil-hidden-table-row {
  @media screen and (max-width: 680px) {
    display: none !important;
  }
}
.min-div-footer {
  height: 41px;
  display: flex;
  display: inline-block;
  background-color: #fbfbfb;
  position: sticky;
  bottom: 0px;
  border-bottom: 1px solid #c0c0c0;
  border-left: 1px solid #c0c0c0;
  border-right: 1px solid #c0c0c0;
}
.min-div-footer-inner {
  height: 41px;
  display: flex;
  display: inline-block;
  background-color: #fbfbfb;
  position: sticky;
  border-bottom: 1px solid #c0c0c0;
  border-left: 1px solid #c0c0c0;
  border-right: 1px solid #c0c0c0;
}
.placeholder-item:nth-of-type(odd) {
  background-color: #f5f5f5;
  box-shadow: 0 1px 2px 0 rgba(33, 33, 33, 0.05);
}
.placeholder-item {
  height: 32px;
  position: relative;
  overflow: hidden;
}
.placeholder-item:nth-of-type(odd)::before {
  content: '';
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
  animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}
@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}
.data-display {
  visibility: hidden;
}
.has-divider-vertical {
  float: left;
  height: 1rem;
  margin: 12px 0.8rem;
  border-left: 1px dotted #bebcbc;
}

.abbr-references {
  font-weight: bold;
  font-family: 'Avenir-Pro-Bold';
}
.img-table-footer {
  margin-top: 9px;
}
.table-container {
  display: flex;
  justify-content: center;
  margin-bottom: 0px !important;
  &__table-general {
    width: 100%;
    table-layout: fixed;
    border-top: 1px solid #c0c0c0;
    border-left: 1px solid #c0c0c0;
    border-right: 1px solid #c0c0c0;
    &__button-container {
      width: 100%;
      height: auto;
      display: none;

      &.show-short-version {
        @media screen and (max-width: 680px) {
          display: inline-block !important;
        }
      }
    }
    &__footer-references {
      width: 100%;
      height: auto;
      display: grid;
      grid-template-columns: 22% 24% 28% 24%;
      grid-template-rows: min-content auto;
      font-family: 'Avenir-Pro-Medium';
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      text-align: center;
      letter-spacing: -0.45px;
      color: #545454;
      padding-top: 10px;
      padding-bottom: 10px;
      @media screen and (max-width: 510px) {
        grid-template-columns: 33% 33% 33%;
      }
      @media screen and (max-width: 420px) {
        grid-template-columns: 52% 48%;
      }

      & > div {
        padding-left: 10px;
        text-align: left;
      }
    }

    &__footer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 100%;
      border-radius: 2px;
      box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.07);
      background-color: #fbfbfb;
      & .spacer {
        width: 1px;
        height: 0.75rem;
        background-color: #979797;
        margin: 0px 16px;
      }
    }

    &__div-table {
      display: table;
      width: 100%;
      border-spacing: 5px 0px;
    }

    &__header-container-ins {
      width: 100%;
      background: #fff;
      height: 32px;
      font-family: 'Circular-Std-Medium';
      color: #132839;
      font-size: 16px;
      text-align: center;
      line-height: 33px;
      @media screen and (max-width: 480px) {
        font-size: 14px;
      }
    }

    &__header-container {
      border-bottom: 2px solid #88da69;
      width: 100%;
      background: #242424;
      height: 40px;
      font-family: 'Circular-Std-Medium';
      font-size: 24px;
      display: grid;
      grid-template-columns: 38px 1fr;
      grid-template-rows: min-content auto;

      img {
        margin-left: 11px;
        margin-top: 4px;
      }
      span {
        color: white;
        height: 34px;
        line-height: 42px;
        font-family: 'Circular-Std-Medium';
        font-size: 24px;
        font-weight: 500;
        letter-spacing: -0.52px;
        text-align: center;
        color: #ffffff;
        padding-right: 38px;
      }
    }
    &__head {
      display: table;
      height: 26px;
      width: 100%;

      &__div-table-col-head {
        float: left;
        display: table-column;
        width: 10%;
        height: 26px;
        line-height: 26px;
        background: #cce8b5;
        font-size: 11px;
        text-align: center;
        font-family: 'Avenir-Pro-Bold';
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.22px;
        color: #132839;
        @media screen and (max-width: 430px) {
          font-size: 9.7px;
        }
      }

      &__div-table-col-head.position {
        width: 8% !important;
        padding-left: 7px;
        @media screen and (max-width: 480px) {
          width: 10.2% !important;
          padding-left: 6px;
        }
        @media screen and (max-width: 380px) {
          width: 11.2% !important;
        }
      }
      &__div-table-col-head.teamtable {
        width: 16.5% !important;
        text-align: center !important;
        @media screen and (max-width: 480px) {
          width: 18.8% !important;
        }
        @media screen and (max-width: 380px) {
          width: 22.3% !important;
        }
      }
      &__div-table-col-head.jj {
        width: 4.8% !important;
        @media screen and (max-width: 480px) {
          width: 4.7% !important;
        }
        @media screen and (max-width: 380px) {
          width: 6.92% !important;
        }
      }
      &__div-table-col-head.jg {
        width: 5% !important;
        @media screen and (max-width: 480px) {
          width: 4.7% !important;
        }
        @media screen and (max-width: 380px) {
          width: 6.92% !important;
        }
      }
      &__div-table-col-head.je {
        width: 4.9% !important;
        @media screen and (max-width: 480px) {
          width: 4.92% !important;
        }
        @media screen and (max-width: 380px) {
          width: 6.92% !important;
        }
      }
      &__div-table-col-head.jp {
        width: 5% !important;
        @media screen and (max-width: 480px) {
          width: 4.92% !important;
        }
        @media screen and (max-width: 380px) {
          width: 6.92% !important;
        }
      }
      &__div-table-col-head.gf {
        width: 4.9% !important;
        @media screen and (max-width: 480px) {
          width: 4.92% !important;
        }
        @media screen and (max-width: 380px) {
          width: 6.92% !important;
        }
      }
      &__div-table-col-head.gc {
        width: 4.8% !important;
        @media screen and (max-width: 480px) {
          width: 4.92% !important;
        }
        @media screen and (max-width: 380px) {
          width: 6.92% !important;
        }
      }
      &__div-table-col-head.dg {
        width: 4.9% !important;
        @media screen and (max-width: 480px) {
          width: 4.92% !important;
        }
        @media screen and (max-width: 380px) {
          width: 6.92% !important;
        }
      }
      &__div-table-col-head.pts {
        width: 7% !important;
      }
      &__div-table-col-head.lastgames {
        width: 23.4% !important;
        @media screen and (max-width: 480px) {
          width: 22% !important;
        }
        @media screen and (max-width: 380px) {
          width: 21.2% !important;
        }
      }
      &__div-table-col-head.prox {
        width: 10.8% !important;
        @media screen and (max-width: 480px) {
          width: 8% !important;
        }
        @media screen and (max-width: 380px) {
          width: 10.5% !important;
        }
      }
    }
    &__div-table-row:nth-of-type(even) {
      background-color: #f7faf9;
    }
    &__div-table-row {
      display: table-row;
      width: auto;
      clear: both;
      height: 32px;

      &__div-table-col {
        float: left;
        display: table-column;
        width: 10%;
        height: 32px;
        line-height: 34px;

        &__game {
          width: 20px;
          height: 20px;
          margin-top: 6px;
          border-radius: 32px;
          margin-left: 2px;
          margin-right: 2px;
          font-family: 'Avenir-Demi';
          font-size: 12px;
          line-height: 22px;
          color: #ffffff;
          @media screen and (max-width: 506px) {
            margin-left: 2px;
            margin-right: 0px;
          }
          @media screen and (max-width: 480px) {
            width: 16px !important;
            height: 16px !important;
            font-size: 10px !important;
            margin-top: 9px;
            margin-left: 2px;
            margin-right: 2px;
            line-height: 16px;
            padding-right: 0px;
          }
        }
        &__game.P {
          background-color: #eb2424;
          padding-left: 1px;
          @media screen and (max-width: 480px) {
            padding-left: 0px;
          }
        }
        &__game.G {
          background-color: #73d44e;
          @media screen and (max-width: 480px) {
            padding-right: 1px;
          }
        }
        &__game.E {
          background-color: #ababab;
        }
      }

      &__div-table-col.position {
        width: 7% !important;
        font-family: 'Avenir-Pro-Medium';
        font-size: 13.2px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.49px;
        color: #132839;
        @media screen and (max-width: 480px) {
          width: 9% !important;
        }
        @media screen and (max-width: 380px) {
          width: 10% !important;
        }
      }
      &__div-table-col.logo {
        width: 7% !important;
        padding-top: 5px;
        @media screen and (max-width: 480px) {
          padding-right: 3px;
          width: 7.5% !important;
        }
        @media screen and (max-width: 380px) {
          width: 10% !important;
        }
      }
      &__div-table-col.teamtable {
        width: 10% !important;
        text-align: left;
        text-decoration: underline;
        font-family: 'Avenir-Demi';
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #132839;
        cursor: pointer;
        @media screen and (max-width: 480px) {
          width: 11.5% !important;
        }
        @media screen and (max-width: 380px) {
          width: 12.8% !important;
        }
      }
      &__div-table-col.jj {
        width: 5% !important;
        font-family: 'Avenir-Pro-Medium';
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        text-align: center;
        letter-spacing: -0.45px;
        color: #132839;
        @media screen and (max-width: 480px) {
          width: 4.92% !important;
        }
        @media screen and (max-width: 380px) {
          width: 7.2% !important;
        }
      }
      &__div-table-col.jg {
        width: 5% !important;
        font-family: 'Avenir-Pro-Medium';
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        text-align: center;
        letter-spacing: -0.45px;
        color: #132839;
        @media screen and (max-width: 480px) {
          width: 4.92% !important;
        }
        @media screen and (max-width: 380px) {
          width: 7.2% !important;
        }
      }
      &__div-table-col.je {
        width: 5% !important;
        font-family: 'Avenir-Pro-Medium';
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        text-align: center;
        letter-spacing: -0.45px;
        color: #132839;
        @media screen and (max-width: 480px) {
          width: 5.4% !important;
        }
        @media screen and (max-width: 380px) {
          width: 7% !important;
        }
      }
      &__div-table-col.jp {
        width: 5% !important;
        font-family: 'Avenir-Pro-Medium';
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        text-align: center;
        letter-spacing: -0.45px;
        color: #132839;
        @media screen and (max-width: 480px) {
          width: 4.92% !important;
        }
        @media screen and (max-width: 380px) {
          width: 7.2% !important;
        }
      }
      &__div-table-col.gf {
        width: 5% !important;
        font-family: 'Avenir-Pro-Medium';
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        text-align: center;
        letter-spacing: -0.45px;
        color: #132839;
        @media screen and (max-width: 480px) {
          width: 4.92% !important;
        }
        @media screen and (max-width: 380px) {
          width: 7.2% !important;
        }
      }
      &__div-table-col.gc {
        width: 5% !important;
        font-family: 'Avenir-Pro-Medium';
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        text-align: center;
        letter-spacing: -0.45px;
        color: #132839;
        @media screen and (max-width: 480px) {
          width: 5.3% !important;
        }
        @media screen and (max-width: 380px) {
          width: 6.92% !important;
        }
      }
      &__div-table-col.dg {
        width: 5% !important;
        font-family: 'Avenir-Pro-Medium';
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        text-align: center;
        letter-spacing: -0.45px;
        color: #132839;
        @media screen and (max-width: 480px) {
          width: 5.3% !important;
        }
        @media screen and (max-width: 380px) {
          width: 7.2% !important;
        }
      }
      &__div-table-col.pts {
        width: 7% !important;
        font-family: 'Avenir-Pro-Bold';
        font-size: 13.2px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.49px;
        color: #132839;
        @media screen and (max-width: 480px) {
          width: 7.2% !important;
        }
      }
      &__div-table-col.lastgames {
        width: 24% !important;
        display: flex;
        justify-content: center;
        @media screen and (max-width: 480px) {
          width: 22.7% !important;
        }
        @media screen and (max-width: 380px) {
          width: 22% !important;
        }
      }
      &__div-table-col.prox {
        width: 10% !important;
        padding-top: 5px;
        padding-left: 6px;
        @media screen and (max-width: 480px) {
          width: 6.4% !important;
          padding-left: 1px;
        }
        @media screen and (max-width: 380px) {
          width: 9.3% !important;
        }
      }
    }
    .movil-game-flag-lastgame-movil {
      display: none;
    }
  }
  @media screen and (max-width: 680px) {
    .button-movil-show {
      display: inline-block;
    }
  }
  @media screen and (max-width: 480px) {
    .movil-game-flag-0,
    .movil-game-flag-1 {
      display: none;
    }
    .movil-game-flag-lastgame-movil {
      display: inline-block;
    }
    .movil-game-flag-lastgame {
      display: none;
    }
  }
  @media screen and (max-width: 380px) {
    .movil-game-flag-g {
      display: none;
    }
  }
}
</style>
